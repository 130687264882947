<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>
    <v-form
      ref="form"
      class="row"
    >
      <v-form
        ref="amplitudeForm"
        class="col-12 col-md-4 d-flex align-top"
      >
        <input-text
          v-model="form.amplitudeMinima"
          class="flex-fill"
          :label="
            $t('modulos.escopo_servico.formulario.faixas.formulario.amplitude')
          "
          :placeholder="
            $t(
              'modulos.escopo_servico.formulario.faixas.formulario.amplitude_min'
            )
          "
          obrigatorio
          :regras-personalizadas="regraAmplitude()"
          type="number"
          @input="valitarAmpliturde"
        />
        <span class="mx-2 mt-11">a</span>
        <input-text
          v-model="form.amplitudeMaxima"
          class="flex-fill mt-5"
          :placeholder="
            $t(
              'modulos.escopo_servico.formulario.faixas.formulario.amplitude_max'
            )
          "
          obrigatorio
          :regras-personalizadas="regraAmplitude()"
          type="number"
          @input="valitarAmpliturde"
        />
      </v-form>
      <input-unidade-medida
        v-model="form.unidadeMedida"
        class="col-12 col-md-3"
        :label="
          $t(
            'modulos.escopo_servico.formulario.faixas.formulario.unidade_medida'
          )
        "
        :multiplos="false"
        obrigatorio
      />
      <input-text
        v-model="form.descricao"
        class="col-12 col-md-2"
        :label="
          $t('modulos.escopo_servico.formulario.faixas.formulario.descricao')
        "
        :max="150"
        obrigatorio
      />
      <input-select
        v-model="form.tipoCMC"
        class="col-12 col-md-3"
        :options="opcoes.tipoCMC"
        :label="
          $t('modulos.escopo_servico.formulario.faixas.formulario.tipo_cmc')
        "
        obrigatorio
      />
      <input-unidade-medida
        v-model="form.unidadeMedidaCMC"

        :listar-unidade-medida-por-grandezas-fisicas-ids="!!form.unidadeMedida?.grandezaFisica?.id"
        :grandezas-fisicas-ids="[form.unidadeMedida?.grandezaFisica?.id]"
        :disabled="form.tipoCMC == 'Percentual'"
        class="col-12 col-md-3"
        :label="
          $t('modulos.escopo_servico.formulario.faixas.formulario.unidade_cmc')
        "
        :multiplos="false"
        :titulo-modal="'modulos.escopo_servico.formulario.faixas.modal.unidade_cmc'"
        obrigatorio
      />
      <input-text
        v-model="form.valorCMCInicial"
        class="col-12 col-md-3"
        :label="
          $t(
            'modulos.escopo_servico.formulario.faixas.formulario.valor_cmc_inicial'
          )
        "
        type="number"
        :obrigatorio="!desabilitar.valorCMCInicial"
        :disabled="desabilitar.valorCMCInicial"
      />
      <input-text
        v-model="form.valorCMCFinal"
        class="col-12 col-md-3"
        :label="
          $t(
            'modulos.escopo_servico.formulario.faixas.formulario.valor_cmc_final'
          )
        "
        type="number"
        :obrigatorio="!desabilitar.valorCMCFinal"
        :disabled="desabilitar.valorCMCFinal"
      />
      <input-text
        v-model="form.formulaCMC"
        class="col-12 col-md-3"
        :label="
          $t('modulos.escopo_servico.formulario.faixas.formulario.formula_cmc')
        "
        :disabled="desabilitar.formulaCMC"
        :max="100"
        :obrigatorio="!desabilitar.formulaCMC"
      />
    </v-form>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao @click="confirmacaoSalvar">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import { EscopoServicoFaixaModel } from '@common/models/cadastros/EscopoServicoFaixaModel';
import { InputUnidadeMedida } from '@components/inputs';
import helpers from '@common/utils/helpers';
import { sortBy } from 'lodash';
export default {
  components: {
    InputUnidadeMedida,
  },
  props: {
    escopoFaixas: { type: Array, default: () => [] },
  },
  data() {
    return {
      id: null,
      ehEdicao: true,
      form: new EscopoServicoFaixaModel({}),
      amplitudeMaximaWatcher: null,
      amplitudeMinimaWatcher: null,
      unidadeMedidaWatcher: null,
      tipoCMCWatcher: null,
      faixasComparacao: [],
      opcoes: {
        tipoCMC: [],
      },
      enums: {
        tipoCMC: {
          formula: 'Formula',
          valorFixo: 'ValorFixo',
          percentual: 'Percentual',
        },
      },
      desabilitar: {
        valorCMCFinal: true,
        formulaCMC: true,
        valorCMCInicial: true,
      },
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.form.id)
        return this.$t(
          'modulos.escopo_servico.formulario.faixas.formulario.editar'
        );
      return this.$t(
        'modulos.escopo_servico.formulario.faixas.formulario.novo'
      );
    },
    tooltipBotaoSalvar: function () {
      if (this.valido) return '';
      return this.$t('modulos.escopo_servico.validacoes.formulario_invalido');
    },
  },
  watch: {
    'form.tipoCMC': {
      handler(valor) {
        if (!this.ehEdicao) {
          this.form.valorCMCInicial = null;
          this.form.valorCMCFinal = null;
          this.form.formulaCMC = null;
        }
        this.ehEdicao = false;
        if (valor == this.enums.tipoCMC.formula) {
          this.desabilitar.formulaCMC = false;
          this.desabilitar.valorCMCFinal = true;
          this.desabilitar.valorCMCInicial = true;
        } else if (
          valor == this.enums.tipoCMC.valorFixo ||
          valor == this.enums.tipoCMC.percentual
        ) {
          this.desabilitar.formulaCMC = true;
          this.desabilitar.valorCMCFinal = true;
          this.desabilitar.valorCMCInicial = false;
        } else {
          this.desabilitar.formulaCMC = true;
          this.desabilitar.valorCMCFinal = false;
          this.desabilitar.valorCMCInicial = false;
        }
      },
    },
    'form.valorCMCInicial': {
      handler(valor) {
        if (
          this.form.tipoCMC == this.enums.tipoCMC.valorFixo ||
          this.form.tipoCMC == this.enums.tipoCMC.percentual
        ) {
          this.form.valorCMCFinal = valor;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.buscarTipoCMC();
  },
  methods: {
    regraAmplitude: function () {
      if (!this.form.amplitudeMaxima || !this.form.amplitudeMinima) return [];

      let regraValor = (v) => {
        let valor = `${v}`;
        let intValue = valor.split('.')[0];
        let decimalValue = valor.split('.')[1];
        if (intValue.length > 10) {
          return this.$t('inputs.rules.maxBetweenDecimalValue', {
            char: 10,
            decimal: 9,
          });
        }

        if (decimalValue && decimalValue.length > 9) {
          return this.$t('inputs.rules.maxBetweenDecimalValue', {
            decimal: 9,
            char: 10,
          });
        }

        return true;
      };

      let regraValorMaior = () =>
        parseFloat(this.form.amplitudeMaxima) >=
        parseFloat(this.form.amplitudeMinima)
          ? true
          : this.$t(
              'inputs.rules.amplitudeMaximaNaoPodeSerMenorQueAmplitudeMinima'
            );
      return [regraValor, regraValorMaior];
    },
    abrirFormulario: function (objeto) {
      const { item, faixasComparacao } = objeto;
      this.faixasComparacao = faixasComparacao;
      if (this.amplitudeMinimaWatcher) this.amplitudeMinimaWatcher();
      if (this.amplitudeMaximaWatcher) this.amplitudeMaximaWatcher();
      if (this.unidadeMedidaWatcher) this.unidadeMedidaWatcher();
      if (this.tipoCMCWatcher) this.tipoCMCWatcher();
      if (item) {
        this.form = new EscopoServicoFaixaModel({ ...item });
      }
      this.amplitudeMinimaWatcher = this.$watch('form.amplitudeMinima', () => {
        this.montarDescricaoAutomatica();
      });
      this.amplitudeMaximaWatcher = this.$watch('form.amplitudeMaxima', () => {
        this.montarDescricaoAutomatica();
      });
      this.unidadeMedidaWatcher = this.$watch(
        'form.unidadeMedida',
        (unidadeMedida) => {
          this.form.unidadeMedidaCMC = unidadeMedida;
          this.montarDescricaoAutomatica();
        }
      );

      this.tipoCMCWatcher = this.$watch('form.tipoCMC', (value) => {
        console.log(value);
        console.log(this.form.tipoCMC);
        if (value == 'Percentual') {
          this.form.unidadeMedidaCMC = this.form.unidadeMedida;
        }
      });
    },

    confirmacaoSalvar: function () {
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    salvar: function () {
      const amplitudeValido = this.$refs.amplitudeForm.validate();
      const valido = this.$refs.form.validate();
      if (!valido || !amplitudeValido) return;
      const cruzamento = this.verificarCruzamentoDeFaixas();

      if (cruzamento.length) {
        this.toastErro(
          this.$t('modulos.escopo_servico.faixa_cruzando', {
            faixa: cruzamento,
          })
        );
        return;
      }
      this.$store.dispatch('Layout/iniciarCarregamento');
      if (this.form.id) {
        const faixaExiste = this.escopoFaixas.find((faixa) => {
          return faixa.id == this.form.id;
        });
        if (faixaExiste) {
          Object.entries(this.form.request).forEach((value) => {
            faixaExiste[value[0]] = value[1];
          });
        }
      } else {
        const form = this.form.request;
        this.escopoFaixas.push({
          ...form,
          id: helpers.gerarNumeroAleatorio(),
        });
      }

      this.$store.dispatch('Layout/terminarCarregamento');
      this.$emit('fechar-formulario');
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$emit('fechar-formulario');
      });
    },
    buscarTipoCMC: function () {
      EnumeradorService.buscar('EnumTipoCMC').then((res) => {
        this.opcoes.tipoCMC = res;
      });
    },
    montarDescricaoAutomatica: function () {
      const descricaoAmplitude = `(${this.form?.amplitudeMinima} a ${
        this.form?.amplitudeMaxima
    })`;
      const descricaoUnidadeMedida = `${
        this.form.unidadeMedida?.simbolo || ''
      }`;

      this.form.descricao = `${descricaoAmplitude.replace(
        /\./g,
        ','
      )} ${descricaoUnidadeMedida}`;
    },
    valitarAmpliturde() {
      if (!this.form.amplitudeMinima && !this.form.amplitudeMaxima) return;
      this.$refs.amplitudeForm.validate();
    },
    verificarCruzamentoDeFaixas: function () {
      const pontosDeCruzamento = [];
      const faixasComparacao = sortBy(
        this.faixasComparacao.filter((faixa) => {
          return faixa.id != this.form.id;
        }),
        ['amplitudeMinima']
      );

      faixasComparacao.forEach((faixa) => {
        if (
          (faixa.amplitudeMinima < Number(this.form.amplitudeMaxima) &&
            faixa.amplitudeMaxima > Number(this.form.amplitudeMinima)) ||
          (faixa.amplitudeMinima == Number(this.form.amplitudeMinima) &&
            faixa.amplitudeMaxima == Number(this.form.amplitudeMaxima))
        ) {
          if (faixa.unidadeMedida.simbolo == this.form.unidadeMedida.simbolo) {
            let pontoCruzamentoMinimo = Math.max(
              faixa.amplitudeMinima,
              this.form.amplitudeMinima
            );
            let pontoCruzamentoMaximo = Math.min(
              faixa.amplitudeMaxima,
              this.form.amplitudeMaxima
            );

            pontosDeCruzamento.push(
              ` ${pontoCruzamentoMinimo} - ${pontoCruzamentoMaximo} ${faixa.unidadeMedida.simbolo}`
            );
          }
        }
      });
      return pontosDeCruzamento;
    },
  },
};
</script>
